
@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

  .image-loop-container {
    display: flex;
    flex-direction: column; /* Stack rows vertically */
    width: 100%; /* Full width */
    overflow: hidden; /* Hide overflow */
    position: relative; /* Set position to relative */
 
  }
  
  .titleText {
    background: linear-gradient(180deg, #EF3F3F, #000);
   
    text-align: center; /* Center the text */
    font-size:3rem; /* Font size */
   
    width: 100%; /* Full width */
    font-family: 'MyCustomFont';
    color: #fff;
    margin-bottom: 0.2%;
          padding: 5px;
          padding-bottom: 15px;

  }
  

  .text-rectangle {
    background-color:  #e0e0e0; /* Solid light grey background */
    padding-top: -10px;
    padding-bottom: 10px;
    text-align: center; /* Center the text */
    font-size:3rem; /* Font size */
   
    width: 100%; /* Full width */
    font-family: 'MyCustomFont';
    color: #EF3F3F;

  }

  .text-rectangle22 {
   
    padding: 10px; /* Padding around the text */

    margin-top: 1.5%;
    text-align: center; /* Center the text */
    font-size:3.75rem; /* Font size */
    
    width: 100%; /* Full width */
    font-family: 'MyCustomFont';
    color: #fff;

  }

  .text-rectangle2 {
   
    padding: 10px; /* Padding around the text */
    margin-top: 3%;
    margin-bottom: .5%;
    text-align: center; /* Center the text */
    font-size:3rem; /* Font size */
    
    width: 100%; /* Full width */
    font-family: 'MyCustomFont';
    color: #fff;

  }

  .text-rectangle50 {
   
    padding: 10px; /* Padding around the text */

    margin-bottom: .5%;
    text-align: center; /* Center the text */
    font-size:3rem; /* Font size */
    
    width: 100%; /* Full width */
    font-family: 'MyCustomFont';
    color: #fff;

  }

  .text-rectangle3 {
   
    padding: 10px; /* Padding around the text */
    margin-top: -1%;
    text-align: center; /* Center the text */
    font-size:1.65rem; /* Font size */

    font-family: 'MyCustomFontSemi';
    color: #fff;

    margin-left: 3%;

    margin-right: 3%;
    margin-top: 2px;
  }
  
  
  
  .overlay {
    position: absolute; /* Position overlay absolutely */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(211, 211, 211, 0.325); /* Light grey with some transparency */
   
    z-index: 1; /* Ensure it appears on top */
  }
  
  .text-overlay {
    position: absolute; /* Position text absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    color: #EF3F3F; /* Text color */
    font-size: 100px; /* Font size */
    
    z-index: 2; /* Ensure it appears on top of the overlay */
    text-align: center; /* Center-align the text */
    width: 100%;
    font-family: 'MyCustomFont';
  }
  
  
  .image-row {
    display: flex;
    width: 100%; /* Full width */
    overflow: hidden; /* Hide overflow */
  }
  
  .image-wrapper {
    flex: 0 0 auto; /* Prevent flex items from growing */
    width: calc(100% / 10); /* Adjust to show 8 images in large screens */
  }
  
  .loop-image {
    width: 100%; /* Images take up full width of their container */
    max-width: 120px;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Crop images to fill the area */
  }

  .text-rectangle22 {
   
   
    padding: 0px; /* Padding around the text */
    text-align: center; /* Center the text */
    font-size:2rem; /* Font size */
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 10px;
    width: 80%; /* Full width */
    font-family: 'MyCustomFontSemi';
    color: #fff;


  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .image-wrapper {
      width: calc(100% / 6); /* Show 4 images on mobile */
    }

    .text-rectangle {
        background-color:  #e0e0e0; /* Solid light grey background */
       
        text-align: center; /* Center the text */
        font-size:20px; /* Font size */
        
        width: 100%; /* Full width */
        font-family: 'MyCustomFont';
        margin-top: 10px;

        color: #EF3F3F;
      }

      .titleText {
        background: linear-gradient(180deg, #EF3F3F, #000);
     
        text-align: center; /* Center the text */
        font-size:1.5rem; /* Font size */
       
        width: 96.5%; /* Full width */
        font-family: 'MyCustomFont';
        color: white;
        padding: 7px;
        padding-bottom: 15px;
        margin-right: 3%;

        margin-bottom: -1%;
      }

      .text-rectangle2 {
   
        margin-top: 1%;
        padding: 10px; /* Padding around the text */
        text-align: center; /* Center the text */
        font-size:1.7rem; /* Font size */
        
        width: 95%; /* Full width */
        font-family: 'MyCustomFont';
        color: #fff;
    
      }

      .text-rectangle22 {
   
        margin-top: 1%;
        padding: 10px; /* Padding around the text */
        text-align: center; /* Center the text */
        font-size:1.1rem; /* Font size */
        
        width: 95%; /* Full width */
        font-family: 'MyCustomFontSemi';
        color: #fff;
 
    
      }

      .text-rectangle3 {
   
        padding: 10px; /* Padding around the text */
        margin-top: -2.5%;
        text-align: center; /* Center the text */
        font-size:1rem; /* Font size */
        margin-left: 3%;

        margin-right: 3%;
        
        font-family: 'MyCustomFontSemi';
        color: #fff;
    
    
      }

      .text-rectangle22 {
   
        padding: 0px; /* Padding around the text */
  margin-left: 2%;
  padding-right: 40px;
        margin-top: 20px;
        margin-bottom: 0px;
        text-align: center; /* Center the text */
        font-size:1.35rem; /* Font size */
        
       
        font-family: 'MyCustomFont';
        color: #fff;
    
      }
      
      
      .text-rectangle50 {
   
        padding: 0px; /* Padding around the text */
        margin-top: 2%;
        margin-bottom: 0%;
      
        font-size:2rem; /* Font size */
        
        width: 100%; /* Full width */
        font-family: 'MyCustomFont';
        color: #fff;
    
      }

      
  }


  
  