/* Style for the navbar */
@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

.pitch-container {
    display: flex;
    justify-content: space-evenly; /* Reduce extra space between cards */
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;

  }
  
  .pitch-card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
    width: 500px; /* Increased card width */
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .soccer-ball {
    font-size: 70px;
    margin-bottom: 0px;
  }
  
  h3 {
    font-size: 2.5rem;
    font-family: 'MyCustomFontSemi';
    color: #D1501F;
    margin-bottom: 0px;
    margin-top: 10px;
    height: 100px;
    font-weight: normal;
    font-style: normal;
  }
  
  p {
    font-size: 2.25rem;
    font-family: 'MyCustomFontBody';
    color: #000;
    line-height: 1.8;
    font-weight: normal;
    font-style: normal;
    margin-top:20px;
  }
  
  @media (max-width: 768px) {
    .pitch-container {
      flex-direction: column;

    }
  
    .pitch-card {
      width: 100%;
      max-width: 250px; /* Max width for smaller screens */
      height: 450px;
    }

    h3 {
        font-size: 1.75rem;
        font-family: 'MyCustomFontSemi';
        color: #D1501F;
        margin-bottom: 0px;
        margin-top: 5px;
        height: 100px;
        font-weight: normal;
        font-style: normal;
      }
      
      p {
        font-size: 1.5rem;
        font-family: 'MyCustomFontBody';
        color: #000;

        font-weight: normal;
        font-style: normal;
        margin-top:5px;
      }

      .soccer-ball {
        font-size: 50px;
        margin-bottom: 0px;
      }
      .enfin {
        margin-top: -40px;
      }
  }
  