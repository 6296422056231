/* Style for the navbar */
@font-face {
  font-family: 'MyCustomFont';
  src: url('./ArchivoBlack-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontBody';
  src: url('./OpenSans-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontSemi';
  src: url('./OpenSans-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  /* Padding on top, bottom, left, and right */
  background-color: #192C4E;
  /* Grey with transparency */
  width: 100%;
  /* Full width */
  box-sizing: border-box;
  /* Include padding in the width */
}

/* Style for the logo */
.navbar-logo {

  /* Green */
  font-size: 1.5rem;
  font-weight: bold;
  flex-shrink: 0;
  /* Prevent shrinking */


}

.logo-img {
  max-height: 180px;
  min-height: 100px;
  /* Set a maximum height for the logo on larger screens */
  margin-left: 0%;
  width: auto;
  width: auto;
  margin-bottom: 0;
  /* Adjust the margin if needed */
}

/* Style for the regular navbar links (non-mobile) */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  color: white;
  text-decoration: none;

  font-size: 1.75rem;
  font-family: 'MyCustomFontSemi';
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.navbar-links a:hover {
  color: #D1501F;
  /* Green */
}

/* Style for the dropdown (mobile) */
.navbar-dropdown {
  font-size: 1.1rem;
  padding: 0.5rem;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  /* Center the dropdown horizontally */
  display: block;
  font-family: 'MyCustomFontBody';
}

/* Special button (if needed) */
.special-button {
  color: #fff;
  /* White text */
  background-color: #D1501F;
  /* Green */
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-transform: uppercase;
  white-space: nowrap;
  /* Prevent text from wrapping */
}




/* Media query for mobile screens */
@media (max-width: 769px) {
  .navbar {
    flex-direction: column;
    /* Stack items vertically */
    align-items: stretch;
    /* Stretch items to full width */
    padding: 1rem;
  }

  .navbar-content {
    width: 100%;
    /* Full width for content */
    flex-direction: column;
    /* Stack links and button vertically */
    align-items: stretch;
  }

  .navbar-links {
    display: none;
    /* Hide regular links */
  }

  .navbar-dropdown {
    display: block;
    /* Show dropdown */
    margin-right: 2%;

    width: 99%;
  }

  /* Make logo image 100% width on mobile */
  .logo-img {
    max-width: 97%;
    max-height: 80px;
    min-height: 80px;

    height: auto;
  }

  /* Style for the logo */
  .navbar-logo {
    color: #F08700;
    /* Green */
    font-size: 1.5rem;
    font-weight: bold;
    flex-shrink: 0;
    /* Prevent shrinking */
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;

  }
}



/* Ensure the navbar looks the same for larger screens */
@media (min-width: 769px) {
  .navbar-dropdown {
    display: none;
    /* Hide dropdown for larger screens */
    margin-top: 3%;

  }

  .navbar-links {
    display: flex;
    /* Show regular links */

  }

  /* Keep logo image auto-sized for larger screens */
  .logo-img {
    margin-left: 0%;
    width: auto;
    max-height: 120px;
    min-height: 100px;
  }
}