/* Style for the navbar */
@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}


.types-container {
    padding: 40px;
    background-color: #D1501F;
    /* Orange background */
}



.types-description {
    margin-bottom: 0px;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;
    color: #fff;
    /* White text for better contrast */
}

.types-description2 {
    font-size: 2rem;
    font-family: 'MyCustomFontSemi';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 40px;
    text-align: center;

    line-height: 1.6;
    color: #fff;
    /* White text for better contrast */
}

.types-list {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.training-box {
    background-color: #ffffff;
    /* White background for contrast */
    color: #333;
    /* Dark text */
    border-radius: 12px;
    padding: 40px;
    width: 45%;
    /* Make boxes wider */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}



.training-box h3 {
    font-size: 2rem;
    font-family: 'MyCustomFontSemi';

    font-weight: normal;
    font-style: normal;
    margin-bottom: 0px;
    color: #192C4E;
    /* Green color for heading */
}

.training-box p {
    font-size: 1.75rem;
    font-family: 'MyCustomFontSemi';

    font-weight: normal;
    font-style: normal;
    margin-top: 0px;
    color: #000;
}

.training-image {
    width: 225px;
    /* Increase the size of the image */
    height: auto;
    /* Maintain aspect ratio */
    margin-bottom: 15px;
    /* Add spacing between the image and the title */
}

@media (max-width: 768px) {
    .types-list {
        flex-direction: column;
        align-items: center;
    }

    .training-box {
        width: 100%;
        max-width: 600px;
    }


    .types-container {
        padding: 10px;
        background-color: #D1501F;
        /* Orange background */
    }

    .types-description2 {
        font-size: 1.5rem;
        font-family: 'MyCustomFontSemi';
        font-weight: normal;
        font-style: normal;
        margin-top: 0px;
        margin-bottom: 40px;
        text-align: center;
        padding: 20px;
        line-height: 1.6;
        color: #fff;
        /* White text for better contrast */
    }



    .training-box {
        background-color: #ffffff;
        /* White background for contrast */
        color: #333;
        /* Dark text */
        border-radius: 12px;
       
       min-width: 75%;
       max-width: 75%;

        /* Make boxes wider */
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease-in-out;
    }


    .training-box h3 {
        font-size: 2rem;
        font-family: 'MyCustomFontSemi';
    
        font-weight: normal;
        font-style: normal;
        margin-bottom: 0px;
        color: #192C4E;
        /* Green color for heading */
    }

    .training-box p {
        font-size: 1.25rem;
        font-family: 'MyCustomFontSemi';
    
        font-weight: normal;
        font-style: normal;
        margin-top: 0px;
        color: #000;
    }
}