.rest {
    background: linear-gradient(900deg, #000, #192C4E );
  
  
  }

  /* Style for the navbar */
@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  .titlePar {
    font-size: 4rem;
    color: white;
    font-family: 'MyCustomFontSemi';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 0px;


  }

  .regPar {
    font-size: 2rem;
    color: white;
    font-family: 'MyCustomFontbody';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 0px;

  }

  

  @media (max-width: 768px) {
  .titlePar {
    font-size: 2rem;
    color: white;
    font-family: 'MyCustomFontSemi';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 2%;
    margin-right: 2%;
  }

  .regPar {
    font-size: 1rem;
    color: white;
    font-family: 'MyCustomFontbody';
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 2%;
    margin-right: 2%;
  }

}