body {
  margin: 0;
  background-color: #192C4E;
  /* Set body background to black */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}



/* Style for the navbar */
@font-face {
  font-family: 'MyCustomFont';
  src: url('./ArchivoBlack-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontBody';
  src: url('./OpenSans-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontSemi';
  src: url('./OpenSans-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Flex container for image and text */
.rectangleContent {
  display: flex;

  gap: 10px;
  /* Space between image and text */

}

/* Rectangle images */
.rectangleImage {
  max-width: 100px;
  /* Set a fixed width for the image */
  height: 100px;
}

/* Rectangle text */
.rectangleText {
  font-weight: bold;
  color: white;
  flex-grow: 1;
  /* Allow text to take up remaining space */
  text-align: left;
  /* Align text to the left */
}

/* Center the header text container */
.centeredHeader {
 

  justify-content: center;
  align-items: center;
  height: 100%;
  /* Ensures the container takes full height of its parent */
  text-align: center;
}


.rest {
  background: linear-gradient(900deg, #000, #192C4E );


}



/* Header Label with background */
.headerLabel {
  color: white;
  font-size: 3rem;
  font-family: "MyCustomFontSemi";
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.85);
  /* Semi-transparent black background */
  padding: 30px;
  border-radius: 25px;
 
  max-width: 65%;

  margin-top: 150px;

  display: inline-block;
  /* Keep the background only around the text */
}

/* Features section with text, buttons, and images */
.featuresSection {
  text-align: center;

  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  /* Space between the section and the rest of the content */
  max-width: 20%;
  /* Adjust this value as needed */
  margin-left: auto;
  /* Center horizontally */
  margin-right: auto;
  /* Center horizontally */
}

/* Features text */
.featuresText {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 20px;

}

p2 {



  margin-bottom: 0px;
}



/* Features text header */
.featuresTextHeader {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 5px;
  color: white;
  text-align: center;
  /* Center the header text */
}

/* Container for the buttons inside the features section */
.buttonsContainer {
  margin-bottom: 20px;
  /* Space between the buttons and images */
  display: flex;
  flex-direction: column;
  /* Stack the header and buttons vertically */
  align-items: center;
  /* Center the contents horizontally */

  padding: 10px;
  min-width: 500px;
  max-width: 35%;
  /* Full width of its parent */

  margin-left: auto;
  /* Center horizontally */
  margin-right: auto;
  /* Center horizontally */
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black background */
}

/* Button group */
.buttonGroup {
  display: flex;
  gap: 10px;
  /* Space between buttons */
  justify-content: center;
  /* Center the buttons horizontally */
  width: 100%;
  /* Full width of its parent */
}






/* Image container inside the features section */
.imageContainer {
  display: flex;
  justify-content: center;
  gap: 1%;

}

/* Individual image wrapper */
.imageWrapper {
  text-align: center;
}

/* Example images */
.exampleImage {
  width: 100%;
  height: auto;
  max-width: 275px;
  /* Adjust this to fit your layout */
}

/* Image description */
.imageDescription {
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent black background */
  padding: 10px;
  border-radius: 8px;
}

/* Button styling */
.special-button {
  background-color: #9EE493;
  color: #fff;
  border: 2px solid #9EE493;
  border-radius: 5px;
  padding: 0.3rem 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.special-button:hover {
  background-color: #86BBD8;
  color: #fff;
  border: 2px solid #86BBD8;
}

/* Container that wraps everything */
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Background for the Home component */
.homeContainer {
  background-image: url('./ball.jpeg');
  background-size: cover;
  /* Fills the entire container */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 1100px;
  max-height: 1000px;
}



/* Sales text section */
.salesText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

/* Top part of the sales text section */
.salesTextTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers content horizontally */
  text-align: center;
  margin-bottom: 20px;
}

/* Sales title */
.salesTitle {
  font-size: 2rem;
  margin-bottom: 10px;
}

/* Sales paragraph */
.salesParagraph {
  font-size: 1.25rem;
  line-height: 1.5;
  max-width: 67.5%;
  /* Optional: Limits the width of the paragraph for better readability */
  margin: 0 auto;
  /* Centers the paragraph within its container */
}



/* Blue rectangles containing images */
/* Blue rectangles containing images */
.blueRectangle {
  background-color: #86BBD8;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 25%;
  flex: 1 1 28%;
  /* Flex-grow and flex-shrink values allow them to resize */
  max-width: 50%;
  /* Minimum width before wrapping occurs */

}

.highlightNumber {
  color: #336699;
  /* Blue color for numbers */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Shadow with a subtle black */
}



/* Bottom part of the sales text section */
.salesTextBottom {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  /* Allows the rectangles to wrap to the next line if needed */
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 10px;
}




/* Individual buttons */
.featureButton {
  background-color: #86BBD8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 14px 26px;
  font-weight: bold;
  font-size: 0.5em;
  ;
  cursor: pointer;
  transition: background-color 0.7s ease-out;
}

.featureButton.selected {
  background-color: #9EE493;
  /* Different background color for selected button */
}

/* Grid layout for mobile screens (under 768px) */
@media (max-width: 768px) {

  /* Background for the Home component */
  .homeContainer {
    background-image: url('./ball.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 450px;
    max-height: 450px;

  }

  .btnClass {
    font-size: 1rem;
  }

  .headerLabel {
    color: white;
    font-size: 1.15rem;
    font-family: "Moderustic", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    padding: 10px;
    border-radius: 8px;
    max-width: 90%;
    margin-top: 65px;

    display: inline-block;
    /* Keep the background only around the text */
  }

  .rest {
    background: linear-gradient(900deg, #000, #192C4E );
    width: 99%;
  
  }


}